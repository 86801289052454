<template>
  <v-container class="content">
    <v-row>
      <v-col align="center">
        <h1 class="mb-8">Login</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6" offset-sm="3" lg="4" offset-lg="4">
        <v-form ref="form" @submit.prevent="submit">
          <v-text-field
            v-model="login.username"
            name="username"
            type="text"
            label="Email"
            outlined
            dense
            placeholder=" "
            persistent-placeholder
          ></v-text-field>
          <v-text-field
            ref="password"
            v-model="login.password"
            name="password"
            type="password"
            label="Password"
            outlined
            dense
            placeholder=" "
            persistent-placeholder
          ></v-text-field>
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn
                block
                color="primary"
                :loading="loading"
                type="submit"
              >
                <v-icon left light>mdi-login</v-icon>
                Login
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="loginFailed">
            <v-col align="center">
              Having trouble? Send an email to sales@goodbev.co
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              Don't have an account? <router-link to="signup">Sign Up</router-link>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "LoginView",
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      login: {},
      loading: false,
      loginFailed: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      axios
        .post("/api/login", this.login)
        .then((response) => {
          this.$store.dispatch('banner/close');
          this.$cookies.set("sesh", response.data.jwt, "1D");
          this.$router.push(response.data.destination);
        })
        .catch((r) => {
          if (r.response.data.errors && r.response.data.errors[0]) {
            this.$store.dispatch('banner/error', {text: r.response.data.errors[0].message, dismissable: false})
            this.login.password = null
            this.$refs.password.focus()
          } else {
            this.$store.dispatch('banner/error', {text: 'An unknown error occurred. Please try again later.', dismissable: false})
          }
          this.loginFailed = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
